import React from 'react'
import { connect } from 'react-redux'

import Layout from 'layout'

import ComparisonBoardComponent from '../components/comparison_board'

import * as actionCreators from '../store/actions'

export class ComparisonBoardPage extends React.Component {
  render() {
    return (
      <Layout>
        <ComparisonBoardComponent />
      </Layout>
    )
  }
}

const mapStateToProps = state => {
  return {
    device: state.device,
    isLogged: state.authentication.authProcess.isLogged,
  }
}

export default connect(mapStateToProps, actionCreators)(ComparisonBoardPage)
