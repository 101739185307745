import React from 'react'
import { connect } from 'react-redux'
import ComparisonAddModelButtonIcon from '../../../../../assets/svg/comparison-add-model-button-icon.svg'

import * as actionCreators from '../../../../../store/actions'
import expandCollapseStatusMap from '../../expandCollapseStatusMap'

const classNames = require('classnames')

export class ComparisonActionPanel extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      bestWorstRenderValue: false,
      differencesRenderValue: false,
    }
  }

  componentDidMount = () => {
    const { comparisonBoard } = this.props
    const { bestWorstEnabled, differencesEnabled } = comparisonBoard
    this.setState({
      bestWorstRenderValue: bestWorstEnabled,
      differencesRenderValue: differencesEnabled,
    })
  }

  handleAddModelBtnClicked = () => {
    const { updateAddProductPopup, comparisonBoard } = this.props
    const { addProductPopup } = comparisonBoard
    addProductPopup.visible = true

    updateAddProductPopup(addProductPopup)
  }

  handleBestWorstChanged = async e => {
    const { updateBestWorstEnabledForComparison } = this.props
    const checkValue = e.target.checked
    await updateBestWorstEnabledForComparison(checkValue)
  }

  handleDifferencesChanged = async e => {
    const { updateDifferencesEnabledForComparison } = this.props
    const checkValue = e.target.checked
    await updateDifferencesEnabledForComparison(checkValue)
  }

  render() {
    const { expandCollapseStatus, onExpandCollape, texts } = this.props

    const { bestWorstRenderValue, differencesRenderValue } = this.state

    return (
      <div className={classNames({ comparison_action_panel: true })}>
        <div
          className={classNames({
            add_model_button: true,
            theme_primary_btn: true,
            sm_btn: true,
          })}
          role="button"
          tabIndex={0}
          onClick={() => {
            this.handleAddModelBtnClicked()
          }}
          onKeyPress={() => {
            this.handleAddModelBtnClicked()
          }}
        >
          <div className="icon">
            <ComparisonAddModelButtonIcon />
          </div>
          Add Model
        </div>
        <div
          className={classNames({
            expand_button: true,
            theme_secondary_btn: true,
            sm_btn: true,
          })}
          role="button"
          tabIndex={0}
          onClick={() => {
            onExpandCollape()
          }}
          onKeyPress={() => {
            onExpandCollape()
          }}
        >
          {expandCollapseStatus === expandCollapseStatusMap.collapsed ? texts.expand_all : texts.collapse_all}
        </div>
        <div className={classNames({ best_worst_button: true })}>
          <label htmlFor="best-worst" className={classNames({ ios_toggle: true })}>
            <input
              id="best-worst"
              type="checkbox"
              checked={bestWorstRenderValue}
              onChange={e => {
                this.setState({ bestWorstRenderValue: !bestWorstRenderValue })
                this.handleBestWorstChanged(e)
              }}
            />
            <span className={classNames({ slider: true })} />
          </label>
          <div className={classNames({ best_worst_text: true })}>{texts.best_worst}</div>
        </div>
        <div className={classNames({ differences_button: true })}>
          <label htmlFor="differences-value" className={classNames({ ios_toggle: true })}>
            <input
              id="differences-value"
              type="checkbox"
              checked={differencesRenderValue}
              onChange={e => {
                this.setState({
                  differencesRenderValue: !differencesRenderValue,
                })
                this.handleDifferencesChanged(e)
              }}
            />
            <span className={classNames({ slider: true })} />
          </label>
          <div className={classNames({ differences_text: true })}>{texts.differences}</div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    device: state.device,
    texts: state.texts.values,
    environment: state.environment,
    comparisonBoard: state.comparisonBoard,
  }
}

export default connect(mapStateToProps, actionCreators)(ComparisonActionPanel)
