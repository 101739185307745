import React from 'react'

const classNames = require('classnames')

export class RefinementOption extends React.Component {
  render() {
    const { label, count, active, onClick } = this.props

    return (
      <div
        className={classNames({
          filter_option: true,
          filter_option_selected: active,
        })}
        onClick={onClick}
        onKeyPress={onClick}
        role="button"
        tabIndex={0}
      >
        {label} ({count})
      </div>
    )
  }
}

export default RefinementOption
