import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../store/actions'
import { getMediaBlobUrl } from '../../../../../../va-corejs-v3/api'

const classNames = require('classnames')

export class ComparisonMediaZoomItem extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      url: null,
    }
  }

  setUrl = async () => {
    const { media } = this.props

    if (media) {
      this.setState({
        url: await getMediaBlobUrl(media.file),
      })
    }
  }

  componentDidMount = async () => {
    await this.setUrl()
  }

  componentDidUpdate = async prevProps => {
    const { media } = this.props

    if (media !== prevProps.media) {
      await this.setUrl()
    }
  }

  render() {
    const { media, onClick } = this.props
    const { url } = this.state

    return (
      <div className={classNames({ zoom_media_container: true })}>
        <div
          className={classNames({ close: true })}
          role="button"
          tabIndex={0}
          onClick={() => {
            onClick()
          }}
          onKeyPress={() => {
            onClick()
          }}
        >
          <svg width="12" height="12" viewBox="0 0 12 12">
            <path
              fill="#fff"
              fillRule="evenodd"
              d="M1.718.293L6 4.575 10.282.293c.39-.39 1.023-.39 1.414 0l.01.011c.391.39.391 1.024 0 1.414L7.426 6l4.282 4.282c.39.39.39 1.023 0 1.414l-.011.01c-.39.391-1.024.391-1.414 0L6 7.426l-4.282 4.282c-.39.39-1.023.39-1.414 0l-.01-.011c-.391-.39-.391-1.024 0-1.414L4.574 6 .293 1.718c-.39-.39-.39-1.023 0-1.414l.011-.01c.39-.391 1.024-.391 1.414 0z"
            />
          </svg>
        </div>
        <img src={url} alt={media.file_name} />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    environment: state.environment,
  }
}

export default connect(mapStateToProps, actionCreators)(ComparisonMediaZoomItem)
