import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../store/actions'

import NextIcon from '../../../assets/svg/pagination-next.svg'
import PrevIcon from '../../../assets/svg/pagination-prev.svg'

const classNames = require('classnames')
const uuidv4 = require('uuid/v4')

export class PaginationComponent extends React.Component {
  render() {
    const { items, toggleItem, selectedItems, texts } = this.props

    function handlePrevClicked() {
      const prevItem = items[0]
      if (prevItem.disabled !== true) {
        toggleItem(parseInt(selectedItems[0], 10) - 1)
      }
    }
    function handleNextClicked() {
      const nextItem = items[items.length - 1]
      if (nextItem.disabled !== true) {
        toggleItem(parseInt(selectedItems[0], 10) + 1)
      }
    }

    const Pages = () => {
      const html = []

      for (let i = 1; i !== items.length - 1; i += 1) {
        const page = items[i]
        html.push(
          <div
            className={classNames({
              search_pagination_button_number: true,
              search_pagination_button: true,
              search_pagination_selected_button_number: page.active,
              search_pagination_button_number_first: i === 1,
            })}
            key={uuidv4()}
            role="button"
            tabIndex={0}
            onClick={() => {
              toggleItem(page.page)
            }}
            onKeyPress={() => {
              toggleItem(page.page)
            }}
          >
            <div>{page.label}</div>
          </div>
        )
      }

      return html
    }

    return (
      <div
        className={classNames({
          search_pagination_container: true,
        })}
      >
        <div
          className={classNames({
            search_pagination_button_prev: true,
            search_pagination_button: true,
          })}
          key={uuidv4()}
          role="button"
          tabIndex={0}
          onClick={handlePrevClicked}
          onKeyPress={handlePrevClicked}
        >
          <div>
            <PrevIcon />
            <div>{texts.previous_results}</div>
          </div>
        </div>
        <div
          className={classNames({
            search_pagination_button_number_container: true,
          })}
        >
          <Pages />
        </div>
        <div
          className={classNames({
            search_pagination_button_next: true,
            search_pagination_button: true,
          })}
          key={uuidv4()}
          role="button"
          tabIndex={0}
          onClick={handleNextClicked}
          onKeyPress={handleNextClicked}
        >
          <div>
            <div>{texts.next_results}</div>
            <NextIcon />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    device: state.device,
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, actionCreators)(PaginationComponent)
