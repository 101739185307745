import React from 'react'
import { connect } from 'react-redux'

import './styles.scss'

import * as actionCreators from '../../store/actions'

import ComparisonSearchAddProducts from './_parts/search_add_products'
import ComparisonPopupBlock from './_parts/popup_block'

import ComparisonAddModelBigIcon from '../../assets/svg/comparison-add-model-big.svg'
import ComparisonAddModelButtonIcon from '../../assets/svg/comparison-add-model-button-icon.svg'
import ComparisonHeadBlock from './_parts/head_block'
import ComparisonBodyBlock from './_parts/body_block'

import LoadingBar from '../loading_bar/index.js'
import expandCollapseStatusMap from './_parts/expandCollapseStatusMap'

const classNames = require('classnames')

export class ComparisonBoardComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      stickyVersion: false,
      expandCollapseStatus: expandCollapseStatusMap.collapsed,
    }
  }

  componentDidMount = async () => {
    const { reloadAllComparedProducts } = this.props
    await reloadAllComparedProducts()
    this.setState({ loading: false })
  }

  handleSearchAddProductsPopup = visible => {
    const { updateAddProductPopup, comparisonBoard } = this.props
    const { addProductPopup } = comparisonBoard
    addProductPopup.visible = visible

    updateAddProductPopup(addProductPopup)
  }

  handleValidateAddProductsPopup = () => {
    this.handleSearchAddProductsPopup(false)
  }

  handleUpdateReferenceTemplate = referenceTemplate => {
    const { updateReferenceTemplate } = this.props
    updateReferenceTemplate(referenceTemplate)
  }

  getDiscendantNodesForAllProducts = () => {
    const { getDescendantNodesAndUpdateRedux } = this.props
    getDescendantNodesAndUpdateRedux()
  }

  handleScroll = e => {
    const { target } = e
    const { scrollTop } = target
    this.setState({ stickyVersion: scrollTop >= 20 })
  }

  handleChangeExpandCollapseStatus = () => {
    const { expandCollapseStatus } = this.state
    const newState =
      expandCollapseStatus === expandCollapseStatusMap.collapsed
        ? expandCollapseStatusMap.expanded
        : expandCollapseStatusMap.collapsed

    this.setState({ expandCollapseStatus: newState })
  }

  render() {
    const { stickyVersion, expandCollapseStatus, loading } = this.state
    const { comparisonBoard, texts } = this.props
    const { comparedProducts, addProductPopup, nodePopup } = comparisonBoard

    return (
      <>
        {addProductPopup.visible && (
          <ComparisonSearchAddProducts
            onCancel={() => this.handleSearchAddProductsPopup(false)}
            onValidate={() => this.handleValidateAddProductsPopup()}
          />
        )}
        {loading === false && nodePopup.visible && <ComparisonPopupBlock />}

        {comparedProducts.length === 0 && (
          <div
            className={classNames({
              comparison_board_empty_container: true,
            })}
          >
            <div
              className={classNames({
                comparison_board_empty_welcome_content: true,
              })}
            >
              <div>
                <ComparisonAddModelBigIcon />
              </div>
              <div
                className={classNames({
                  comparison_board_empty_welcome_content_text: true,
                })}
              >
                {texts.comparison_board_welcome_text}
              </div>
              <button
                type="button"
                className={classNames({
                  comparison_board_add_model_btn: true,
                  sm_btn: true,
                  theme_primary_btn: true,
                })}
                onClick={() => this.handleSearchAddProductsPopup(true)}
              >
                <ComparisonAddModelButtonIcon />
                <span className="add_model_text">{texts.add_model}</span>
              </button>
            </div>
          </div>
        )}

        {comparedProducts.length > 0 && loading === true && (
          <div className={classNames({ outer_loader: true })}>
            <div className={classNames({ inner_loader: true })}>
              <LoadingBar />
            </div>
          </div>
        )}
        {comparedProducts.length > 0 && loading === false && (
          <div
            className={classNames({
              comparison_board_container: true,
            })}
          >
            <div
              className={classNames({
                comparison_board_content_outer_body: true,
              })}
              onScroll={e => {
                this.handleScroll(e)
              }}
            >
              <div
                className={classNames({
                  comparison_board_content_inner_body: true,
                  sticky_enabled: stickyVersion,
                })}
              >
                <div
                  className={classNames({
                    comparison_board_head_block: true,
                    sticky_enabled: stickyVersion,
                  })}
                >
                  <ComparisonHeadBlock
                    stickyVersion={stickyVersion}
                    expandCollapseStatus={expandCollapseStatus}
                    onExpandCollape={() => this.handleChangeExpandCollapseStatus()}
                  />
                </div>

                <div
                  className={classNames({
                    comparison_board_body_block: true,
                    sticky_enabled: stickyVersion,
                  })}
                >
                  <ComparisonBodyBlock expandCollapseStatus={expandCollapseStatus} />
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    device: state.device,
    texts: state.texts.values,
    comparisonBoard: state.comparisonBoard,
    search: state.search,
  }
}

export default connect(mapStateToProps, actionCreators)(ComparisonBoardComponent)
