import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../store/actions'
import { getMediaBlobUrl } from '../../../../../../va-corejs-v3/api'

const uuidv4 = require('uuid/v4')

const classNames = require('classnames')

export class ComparisonMediaCarouselThumb extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      url: null,
    }
  }

  setUrl = async () => {
    const { media } = this.props

    if (media) {
      this.setState({
        url: await getMediaBlobUrl(media.file_thumb),
      })
    }
  }

  componentDidMount = async () => {
    await this.setUrl()
  }

  componentDidUpdate = async prevProps => {
    const { media } = this.props

    if (media !== prevProps.media) {
      await this.setUrl()
    }
  }

  render() {
    const { media, thumbWidth, onThumbClicked, index } = this.props
    const { url } = this.state

    return (
      <span
        key={uuidv4()}
        className={classNames({ media_itm: true })}
        style={{ width: thumbWidth - 5 }}
        role="button"
        tabIndex={0}
        onClick={() => {
          onThumbClicked(index)
        }}
        onKeyPress={() => {
          onThumbClicked(index)
        }}
      >
        <img src={url} alt={media.file_name} />
      </span>
    )
  }
}

const mapStateToProps = state => {
  return {
    environment: state.environment,
  }
}

export default connect(mapStateToProps, actionCreators)(ComparisonMediaCarouselThumb)
