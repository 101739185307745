import React from 'react'
import { connect } from 'react-redux'
import { getComparisonPopupElementWidth } from '../../../../../../va-corejs-v3/utils'

import * as actionCreators from '../../../../../store/actions'
import scopePropsMap from '../../../../scoring/_parts/helper/scopePropsMap'
import nodeDefinitionTypeMap from '../../../../scoring_tree/helper/nodeDefinitionTypeMap'
import Score from '../../../../commons/score'
import mediaCollectionsMap from '../../../../../../va-corejs-v3/utils/mediaCollectionsMap'
import ComparisonMediaCarouselPreview from './mediaCarouselPreview'
import ComparisonMediaCarouselThumb from './mediaCarouselThumb'
import ComparisonMediaZoomItem from './mediaZoomItem'

const classNames = require('classnames')

export class ComparisonMediaCarousel extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      pInfo: {},
      media: [],
      selectedMediaIdx: 0,
      selectedMediaZoom: false,
      scrolledItems: 0,
      node: {},
    }
  }

  componentDidMount = async () => {
    const { data, isForecast, comparisonBoard } = this.props
    const { nodePopup } = comparisonBoard
    const { nodeDefId } = nodePopup

    const { nodes } = data

    const node = nodes[nodeDefId]

    const pInfo = this.getProductInfo(node)

    const media = this.getMedia(nodeDefId, isForecast)

    this.setState({ loading: false, pInfo, media, node })
  }

  componentWillUnmount = () => {
    this.setState = () => {}
  }

  getMedia = (nodeDefId, isForecast) => {
    const { data } = this.props
    const { nodes } = data
    const node = nodes[nodeDefId]
    const parentNode = nodes[node.parent_id]
    const childrenNodes = []

    // Fixed excluding null conditions
    // TO DO: check why children_ids and parentNode is null

    if (node.children_ids)
      node.children_ids.forEach(id => {
        childrenNodes.push(nodes[id])
      })

    let { media } = node

    // Use also children media
    if (childrenNodes)
      childrenNodes.forEach(element => {
        media = media.concat(element.media)
      })

    if (media.length <= 0 && parentNode) {
      // Use parent media
      const { media: parentMedia } = parentNode
      media = parentMedia
    }

    media = isForecast
      ? media.filter(m => m.collection === mediaCollectionsMap.forecast_node_pictures)
      : media.filter(m => m.collection === mediaCollectionsMap.node_pictures)

    return media
  }

  getProductInfo = node => {
    const { data } = this.props
    const { props } = data
    const { percentage, score, maxScore } = node

    let brand
    let model
    let grade = ''
    for (let i = 0; i !== props.length; i += 1) {
      const prop = props[i]
      const { slug } = prop
      if (slug === scopePropsMap.brand) {
        brand = prop.value.body.en
      } else if (slug === scopePropsMap.model) {
        model = prop.value.body.en
      } else if (slug === scopePropsMap.comm_name_grade) {
        grade = prop.value.body.en
      }
    }

    const prodInfo = {
      name: `${brand} ${model}`,
      grade,
      percentage,
      score,
      maxScore,
    }

    return prodInfo
  }

  renderNodeMedia = thumbWidth => {
    const { media } = this.state
    const html = []

    for (let i = 0; i !== media.length; i += 1) {
      const nMedia = media[i]
      html.push(
        <ComparisonMediaCarouselThumb
          media={nMedia}
          index={i}
          thumbWidth={thumbWidth}
          onThumbClicked={index => {
            this.handleMediaThumbClicked(index)
          }}
        />
      )
    }
    return html
  }

  handleMediaNavClicked = async newScrolledItems => {
    const { comparisonBoard, handleUpdatesyncPhotoScrolledIdx } = this.props
    const { syncMedia } = comparisonBoard

    this.setState({ scrolledItems: newScrolledItems })

    if (syncMedia) {
      handleUpdatesyncPhotoScrolledIdx(newScrolledItems)
    }
  }

  handleMediaThumbClicked = async selectedMediaIndex => {
    const { comparisonBoard, handleUpdateSyncMediaIdx } = this.props
    const { syncMedia } = comparisonBoard

    this.setState({ selectedMediaIdx: selectedMediaIndex })
    if (syncMedia) {
      handleUpdateSyncMediaIdx(selectedMediaIndex)
    }
  }

  selectedMedia = () => {
    const { comparisonBoard, syncMediaIdx } = this.props
    const { syncMedia } = comparisonBoard
    const { selectedMediaIdx, media } = this.state

    let selectedIndex = selectedMediaIdx

    if (syncMedia === true) {
      if (syncMediaIdx < media.length) {
        selectedIndex = syncMediaIdx
      }
    }

    return media[selectedIndex] || null
  }

  render() {
    const { comparisonBoard, syncPhotoScrolledIdx, device } = this.props
    const { nodePopup, syncMedia, order } = comparisonBoard
    const { type } = nodePopup

    const { size } = device
    const { innerWidth } = size
    const itemSize = getComparisonPopupElementWidth(innerWidth, order.length)
    const { itemWidth, marginBetweenItems, thumbWidth, fittingThumbs } = itemSize

    const { loading, pInfo, media, selectedMediaZoom, scrolledItems, node } = this.state

    let globalScrolledItems = scrolledItems

    if (syncMedia === true) {
      // Synced scroll
      if (syncPhotoScrolledIdx <= media.length - fittingThumbs) {
        globalScrolledItems = syncPhotoScrolledIdx
      } else {
        globalScrolledItems = media.length - fittingThumbs
      }
    }

    const left = 0 - globalScrolledItems * thumbWidth
    const selMedia = this.selectedMedia()

    const previewHtml = (
      <ComparisonMediaCarouselPreview
        media={selMedia}
        onSelectedMediaZoom={value => {
          this.setState({ selectedMediaZoom: value })
        }}
      />
    )

    return (
      <>
        {loading === false && (
          <>
            <div
              className={classNames({ media_container: true })}
              style={{
                width: itemWidth,
                marginRight: marginBetweenItems,
              }}
            >
              <div className={classNames({ media_info: true })}>
                <div className={classNames({ left_col: true })}>
                  <div className={classNames({ name: true })}>{pInfo.name}</div>
                  <div className={classNames({ grade: true })}>{pInfo.grade}</div>
                </div>
                <div className={classNames({ right_col: true })}>
                  {type === nodeDefinitionTypeMap.item && (
                    <>
                      <span className={classNames({ percentage: true })}>{pInfo.percentage.toFixed(0)}%</span>
                      <span className={classNames({ dot_divider: true })}>·</span>
                      <span className={classNames({ score: true })}>
                        {pInfo.score.toFixed(2)}/{pInfo.maxScore.toFixed(2)}
                      </span>
                    </>
                  )}
                  {type === nodeDefinitionTypeMap.criterion && (
                    <Score score={pInfo.score} classes="criterion_score_value" isDefault={node.is_default} />
                  )}
                </div>
              </div>
              <div
                className={classNames({
                  media_selected: true,
                  no_media: media.length === 0,
                })}
                style={{
                  height: itemWidth > 763 ? (763 / 100) * 66 : (itemWidth / 100) * 66,
                }}
              >
                {previewHtml}
              </div>

              <div className={classNames({ media_carousel: true })}>
                <div
                  className={classNames({ carousel_visible_area: true })}
                  style={{
                    left,
                    width: media.length * thumbWidth,
                  }}
                >
                  {this.renderNodeMedia(thumbWidth)}
                </div>
                {media.length > fittingThumbs && globalScrolledItems < media.length - fittingThumbs && (
                  <div
                    className={classNames({ media_btn: true, next: true })}
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      this.handleMediaNavClicked(globalScrolledItems + 1)
                    }}
                    onKeyPress={() => {
                      this.handleMediaNavClicked(globalScrolledItems + 1)
                    }}
                  >
                    <svg width="15" height="15" viewBox="0 0 15 26">
                      <path
                        fill="#fff"
                        d="M1.748 26c.485 0 .882-.176 1.22-.498l11.43-11.139c.396-.41.602-.835.602-1.363 0-.513-.191-.967-.602-1.348L2.968.498C2.63.176 2.233 0 1.748 0 .764 0 0 .762 0 1.73c0 .468.206.923.529 1.26l10.299 10.025-10.3 9.995c-.337.352-.528.777-.528 1.26C0 25.239.764 26 1.748 26z"
                      />
                    </svg>
                  </div>
                )}
                {globalScrolledItems > 0 && (
                  <div
                    className={classNames({ media_btn: true, prev: true })}
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      this.handleMediaNavClicked(globalScrolledItems - 1)
                    }}
                    onKeyPress={() => {
                      this.handleMediaNavClicked(globalScrolledItems - 1)
                    }}
                  >
                    <svg width="15" height="15" viewBox="0 0 15 26">
                      <path
                        fill="#FFF"
                        d="M13.252 26c-.485 0-.882-.176-1.22-.498L.602 14.363C.206 13.953 0 13.528 0 13c0-.513.191-.967.602-1.348L12.032.498C12.37.176 12.767 0 13.252 0 14.236 0 15 .762 15 1.73c0 .468-.206.923-.529 1.26L4.172 13.015l10.3 9.995c.337.352.528.777.528 1.26 0 .968-.764 1.73-1.748 1.73z"
                      />
                    </svg>
                  </div>
                )}
              </div>
            </div>
            {selectedMediaZoom === true && (
              <ComparisonMediaZoomItem
                media={selMedia}
                onClick={() => {
                  this.setState({ selectedMediaZoom: false })
                }}
              />
            )}
          </>
        )}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    device: state.device,
    texts: state.texts.values,
    environment: state.environment,
    comparisonBoard: state.comparisonBoard,
  }
}

export default connect(mapStateToProps, actionCreators)(ComparisonMediaCarousel)
