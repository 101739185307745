import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../store/actions'

const classNames = require('classnames')

export class SelectedForComparisonFilter extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      filterSelected: false,
    }
  }

  findCustomQueryInQueries = (cq, list) => {
    let idx = -1
    for (let i = 0; i !== list.length; i += 1) {
      const q = list[i]

      if (JSON.stringify(q) === JSON.stringify(cq)) {
        idx = i
        break
      }
    }
    return idx
  }

  updateQuery = async () => {
    const { searchkit, comparisonBoard } = this.props
    const { addProductPopup, chosenProductsForComparison } = comparisonBoard
    const { showSelectedEnabled } = addProductPopup

    const { query } = searchkit
    const { queries } = query.index

    const selectedProductIDS = []
    for (let i = 0; i !== chosenProductsForComparison.length; i += 1) {
      const p = chosenProductsForComparison[i]
      const { id } = p
      selectedProductIDS.push(id)
    }
    const filterQuery = {
      bool: {
        must: [
          {
            terms: {
              id: selectedProductIDS,
            },
          },
        ],
      },
    }

    const filterIdx = this.findCustomQueryInQueries(filterQuery, queries)

    let refreshSearch = false
    if (showSelectedEnabled === true && filterIdx < 0) {
      queries.push(filterQuery)
      refreshSearch = true
    } else if (showSelectedEnabled === false && filterIdx >= 0) {
      queries.splice(filterIdx, 1)
      refreshSearch = true
    }

    if (refreshSearch === true) {
      try {
        searchkit.performSearch()
      } catch (error) {
        console.log(error)
      }
    }
  }

  componentDidMount = async () => {
    const { comparisonBoard } = this.props
    const { addProductPopup } = comparisonBoard
    const { showSelectedEnabled } = addProductPopup

    this.setState({ filterSelected: showSelectedEnabled })
    setTimeout(() => {
      this.updateQuery()
    }, 1000)
  }

  handleFilterClicked = async () => {
    const { comparisonBoard, updateAddProductPopup } = this.props
    const { addProductPopup } = comparisonBoard
    const { showSelectedEnabled } = addProductPopup

    const filterEnabled = !showSelectedEnabled
    addProductPopup.showSelectedEnabled = filterEnabled

    this.setState({ filterSelected: filterEnabled })

    await updateAddProductPopup(addProductPopup)
    this.updateQuery()
  }

  render() {
    const { filterSelected } = this.state
    const { texts } = this.props

    return (
      <div
        className={classNames({
          selected_for_comparison_filter: true,
          selected_filter: filterSelected,
        })}
        role="button"
        tabIndex={0}
        onClick={() => {
          this.handleFilterClicked()
        }}
        onKeyPress={() => {
          this.handleFilterClicked()
        }}
      >
        {texts.selected_for_comparison}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    device: state.device,
    texts: state.texts.values,
    comparisonBoard: state.comparisonBoard,
    searchkit: state.search.searchkit,
  }
}

export default connect(mapStateToProps, actionCreators)(SelectedForComparisonFilter)
