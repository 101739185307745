import React from 'react'
import { connect } from 'react-redux'
import { Player } from 'video-react'

import * as actionCreators from '../../../../../store/actions'
import { getMediaBlobUrl } from '../../../../../../va-corejs-v3/api'

const classNames = require('classnames')

export class ComparisonMediaCarouselPreview extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      url: null,
    }
  }

  setUrl = async () => {
    const { media } = this.props

    if (media) {
      this.setState({
        url: await getMediaBlobUrl(media.file),
      })
    }
  }

  componentDidMount = async () => {
    await this.setUrl()
  }

  componentDidUpdate = async prevProps => {
    const { media } = this.props

    if (media !== prevProps.media) {
      await this.setUrl()
    }
  }

  render() {
    const { media, onSelectedMediaZoom } = this.props
    const { url } = this.state

    let mediaType = null
    if (media !== null) {
      // Bad thing: the same media model could have file_type attribute or mime_type sometimes
      mediaType = media.file_type ? media.file_type.split('/')[0] : media.mime_type.split('/')[0]
    }

    if (media === null) {
      return (
        <div className={classNames({ no_media: true })}>
          <svg width="45" height="40" viewBox="0 0 45 40">
            <path
              fill="#FFF"
              stroke="#FFF"
              strokeWidth=".2"
              d="M40.854 2.75c.413.405.42 1.068.015 1.482l-.015.015L5.705 38.688c-.424.416-1.103.416-1.528 0-.413-.405-.42-1.069-.015-1.482l.015-.015h0L39.326 2.75c.424-.416 1.103-.416 1.528 0zm-1.44 7.374c3.041 0 4.586 1.532 4.586 4.515h0v15.808c0 2.983-1.545 4.514-4.586 4.514h0-26.44c-.39 0-.756-.025-1.096-.075l2.055-2.015h25.432c1.545 0 2.467-.793 2.538-2.339l.005-.197V14.75c0-1.675-.933-2.536-2.543-2.536h0-4.184l-.166-.001 2.132-2.089zM28.92 1c3.042 0 4.586 1.531 4.586 4.514h0v.692l-3.024 2.962c-.501-.418-.957-.555-1.787-.573l-.21-.002h-4.763c-1.303 0-1.738.223-2.607 1.18h0l-1.223 1.356c-.789.862-1.497 1.085-2.865 1.085h0-4.007c-1.609 0-2.542.861-2.542 2.536h0l-.001 14.021-2.083 2.042-.005-.114-.003-.252v-3.988H5.603C2.545 26.459 1 24.944 1 21.945h0V5.515C1 2.514 2.545 1 5.603 1h0zm2.609 14.63c1.831 1.512 2.991 3.798 2.991 6.378 0 4.61-3.717 8.28-8.32 8.28-2.632 0-4.971-1.192-6.497-3.07l1.44-1.412c1.16 1.515 2.997 2.487 5.057 2.487 3.492 0 6.325-2.791 6.325-6.285 0-2.028-.955-3.82-2.44-4.966zM28.873 3.09H5.635c-1.642 0-2.527.861-2.527 2.536h0v16.223c0 1.659.885 2.52 2.527 2.52h2.752v-9.73c0-2.983 1.545-4.515 4.586-4.515h3.573c1.207 0 1.577-.207 2.269-.989h0l1.255-1.371c.772-.846 1.513-1.245 3.042-1.245h5.986c1.062 0 1.738.192 2.317.59h0V5.627c0-1.675-.933-2.536-2.542-2.536h0zm-3.054 10.648l-3.015 2.955c-.77.483-1.428 1.127-1.924 1.885l-3 2.941c.244-4.252 3.662-7.592 7.94-7.781zm11.31.98c.981 0 1.77.782 1.77 1.771 0 .973-.789 1.755-1.77 1.755-.982 0-1.787-.782-1.787-1.755 0-.989.805-1.77 1.786-1.77z"
              opacity=".5"
            />
          </svg>
        </div>
      )
    }
    if (media !== null && mediaType === 'video') {
      return <Player playsInline src={url} fluid={false} height="100%" width="100%" />
    }
    return (
      <img
        src={url}
        alt={media.file_name}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
        role="button"
        tabIndex={0}
        onClick={() => {
          onSelectedMediaZoom(true)
        }}
        onKeyPress={() => {
          onSelectedMediaZoom(true)
        }}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    environment: state.environment,
  }
}

export default connect(mapStateToProps, actionCreators)(ComparisonMediaCarouselPreview)
