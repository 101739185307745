import React from 'react'
import { connect } from 'react-redux'

import './styles.scss'

import * as actionCreators from '../../../../store/actions'

import AddToComparisonIcon from '../../../../assets/svg/compar-add.svg'
import RemoveFromComparisonIcon from '../../../../assets/svg/compar-remove.svg'

import ScorePanel from '../../../commons/score_panel'
import scopePropsMap from '../../../scoring/_parts/helper/scopePropsMap'
import { getMediaBlobUrl } from '../../../../../va-corejs-v3/api'

const classNames = require('classnames')

export class ComparisonSearchHit extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      thumbUrl: null,
      brandImgUrl: null,
    }
  }

  handleSelectProductForComparison = item => {
    const { isLoading } = this.props
    if (isLoading) {
      return
    }

    const { chosenProductsForComparison, updateChosenProductsForComparison } = this.props

    let indexOfProductInChosenProductsList = -1
    let projectModeProductsCounter = 0
    const itemProjectMode = item.project_mode

    for (let i = 0; i !== chosenProductsForComparison.length; i += 1) {
      const chosenProduct = chosenProductsForComparison[i]

      if (chosenProduct.project_mode) {
        projectModeProductsCounter += 1
      }

      if (chosenProduct.id === item.id) {
        indexOfProductInChosenProductsList = i
        break
      }
    }

    if (indexOfProductInChosenProductsList >= 0) {
      chosenProductsForComparison.splice(indexOfProductInChosenProductsList, 1)
    } else {
      if (
        (projectModeProductsCounter === 2 && itemProjectMode) || // No more than 2 forecast products
        (projectModeProductsCounter === 2 && chosenProductsForComparison.length === 3) || // No more than 2 forecast products and 1 benchmark product
        (chosenProductsForComparison.length === 4 && itemProjectMode) || // Can't have 4 benchamrk products and 1 forecast product
        chosenProductsForComparison.length === 5 // No more than 5 benchmark products
      ) {
        // Don't add the product to the comparison
        return
      }

      chosenProductsForComparison.push(item)
    }

    updateChosenProductsForComparison([...chosenProductsForComparison])
  }

  componentDidMount = async () => {
    const { result: hit } = this.props

    const item = hit._source

    const { props } = item
    const { brand } = props

    const cover = item.cover === '' ? '' : await getMediaBlobUrl(item.cover)

    this.setState({
      thumbUrl: cover,
      brandImgUrl: brand.media === '' ? '' : await getMediaBlobUrl(brand.media),
    })
  }

  render() {
    const { chosenProductsForComparison, environment, result: hit, isLoading, texts } = this.props
    const { thumbUrl, brandImgUrl } = this.state

    const item = hit._source
    const scorePanel = item.score_panel

    const { props } = item
    const { brand, model } = props

    const brandValue = brand ? brand.value : ''
    const modelValue = model ? model.value : ''
    const productName = [brandValue, modelValue].join(' ')

    const isHitSelected = chosenProductsForComparison.filter(product => product.id === item.id).length > 0

    return (
      <div
        className={classNames({
          search_hit_container: true,
          selected_hit: isHitSelected,
          theme_box_shadow_3: isHitSelected,
          disabled: isLoading,
        })}
        role="button"
        tabIndex={0}
        onKeyDown={() => {
          this.handleSelectProductForComparison(item)
        }}
        onClick={() => this.handleSelectProductForComparison(item)}
      >
        <div style={{ backgroundImage: `url(${thumbUrl})` }} className={classNames({ search_hit_cover: true })}>
          {item.project_mode && <div className="project_mode_overlay" />}
        </div>

        {brand.media && (
          <div
            style={{ backgroundImage: `url(${brandImgUrl})` }}
            className={classNames({ search_hit_brand_image: true })}
          />
        )}
        {!brand.media && (
          <div
            className={classNames({
              search_item_brand_logo_placeholder: true,
            })}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
              <circle
                cx="609"
                cy="222"
                r="14.5"
                fill="#D8D8D8"
                fillRule="evenodd"
                stroke="#000"
                strokeOpacity=".1"
                transform="translate(-594 -207)"
              />
            </svg>
          </div>
        )}
        <div className={classNames({ search_hit_title: true })}>
          <div className={classNames({ search_hit_product_name: true })}>{productName}</div>
          <div className={classNames({ search_hit_product_subname: true })}>
            {item.props[scopePropsMap.comm_name_grade] !== undefined && item.props[scopePropsMap.comm_name_grade].value}
          </div>
          {item.project_mode && <div className="project_mode_label">{texts.project}</div>}
        </div>
        <div className={classNames({ search_hit_score_panel: true })}>
          {scorePanel && (
            <ScorePanel scorePanel={scorePanel} size="mashed" maxProductScore={environment.config.max_product_score} />
          )}
        </div>
        <div
          className={classNames({
            search_hit_select_container: true,
            theme_svg: !isHitSelected,
            theme_circle: isHitSelected,
          })}
        >
          {isHitSelected && <RemoveFromComparisonIcon />}
          {!isHitSelected && <AddToComparisonIcon />}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    device: state.device,
    texts: state.texts.values,
    environment: state.environment,
    chosenProductsForComparison: state.comparisonBoard.chosenProductsForComparison,
    isLoading: state.comparisonBoard.nodePopup.isLoading,
  }
}

export default connect(mapStateToProps, actionCreators)(ComparisonSearchHit)
