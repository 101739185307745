import React, { Component } from 'react'

import './styles.scss'

const classNames = require('classnames')

class Score extends Component {
  render() {
    const { score, classes, isDefault } = this.props

    const scoreStyle = isDefault ? 'is_default' : `criterion_score_value_${score.toString().replace('.', '_')}`

    return <div className={classNames({ [scoreStyle]: true }, classes)}>{score}</div>
  }
}

export default Score
