import React from 'react'
import { connect } from 'react-redux'
import DragAndDropIcon from '../../../../../assets/svg/drag-icon.svg'
import * as actionCreators from '../../../../../store/actions'
import scopePropsMap from '../../../../scoring/_parts/helper/scopePropsMap'
import EditScoringIcon from '../../../../../assets/svg/edit-scoring-icon.svg'
import EditScoringStickyIcon from '../../../../../assets/svg/pen-sticky.svg'
import EyeIcon from '../../../../../assets/svg/eye.svg'
import EyeStickyIcon from '../../../../../assets/svg/eye-sticky.svg'
import HearthIcon from '../../../../../assets/svg/heart-inactive.svg'
import HearthStickyIcon from '../../../../../assets/svg/heart-sticky.svg'
import HearthIconActive from '../../../../../assets/svg/heart-active.svg'
import HearthStickyIconActive from '../../../../../assets/svg/heart-sticky-active.svg'
import RemoveIcon from '../../../../../assets/svg/x.svg'
import RemoveStickyIcon from '../../../../../assets/svg/x-sticky.svg'
import ScorePanel from '../../../../commons/score_panel'
import DragAndDropSmallIcon from '../../../../../assets/svg/drag-icon-small.svg'
import { getMediaBlobUrl } from '../../../../../../va-corejs-v3/api'

const classNames = require('classnames')

export class ComparisonHeadItem extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      thumbUrl: null,
      brandImgUrl: null,
    }
  }

  componentDidMount = async () => {
    const { product } = this.props

    this.setState({
      thumbUrl: product.cover === '' ? '' : await getMediaBlobUrl(product.cover),
      brandImgUrl: product.props.brand.media === '' ? '' : await getMediaBlobUrl(product.props.brand.media),
    })
  }

  render() {
    const {
      id,
      margin,
      width,
      product,
      deltaValue,
      stickyVersion,
      onGoToScoringClicked,
      onDeltaClicked: parentOnDeltaClicked,
      onRemoveScoringClicked,
      authentication,
      environment,
      projectMode,
      texts,
    } = this.props

    const { thumbUrl, brandImgUrl } = this.state

    const { props, score_panel: scorePanel } = product
    const productBrand = props.brand.value
    const productModel = props.model.value
    const commercialNameGrade =
      props[scopePropsMap.comm_name_grade] !== undefined ? props[scopePropsMap.comm_name_grade].value : ''

    const onDeltaClicked = () => {
      parentOnDeltaClicked({ id: product.id, scoreSet: projectMode && product.project_mode ? 'forecast' : 'benchmark' })
    }

    const isDeltaActive = () => {
      const scoreSet = projectMode && product.project_mode ? 'forecast' : 'benchmark'
      return deltaValue && deltaValue.id === product.id && deltaValue.scoreSet === scoreSet
    }

    return (
      <div
        className={classNames({
          single_product_column: true,
          single_product_column_small: stickyVersion,
        })}
        id={id}
      >
        {stickyVersion === false && (
          <div className={classNames({ product_card: true })} style={{ width, marginRight: margin }}>
            <div style={{ backgroundImage: `url(${thumbUrl})` }} className="product_card_cover">
              {projectMode && product.project_mode && <div className="project_mode_overlay" />}
              <div className={classNames({ product_card_buttons: true })}>
                <div
                  className={classNames({
                    card_btn: true,
                    drag_btn: true,
                  })}
                >
                  <DragAndDropIcon />
                </div>
                <div
                  className={classNames({
                    card_btn: true,
                    edit_btn: true,
                  })}
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    onGoToScoringClicked(id)
                  }}
                  onKeyPress={() => {
                    onGoToScoringClicked(id)
                  }}
                >
                  {authentication.user.parsedPermissions.canEditProduct &&
                    !product.is_locked &&
                    authentication.user.parsedPermissions.canViewProductScoring && <EditScoringIcon />}

                  {(!authentication.user.parsedPermissions.canEditProduct || product.is_locked) && <EyeIcon />}
                </div>

                <div
                  className={classNames({
                    card_btn: true,
                    reference_btn: true,
                    active: isDeltaActive(),
                  })}
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    onDeltaClicked()
                  }}
                  onKeyPress={() => {
                    onDeltaClicked()
                  }}
                >
                  {isDeltaActive() && <HearthIconActive />}
                  {!isDeltaActive() && <HearthIcon />}
                </div>
                <div
                  className={classNames({
                    card_btn: true,
                    remove_btn: true,
                  })}
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    onRemoveScoringClicked(id)
                  }}
                  onKeyPress={() => {
                    onRemoveScoringClicked(id)
                  }}
                >
                  <RemoveIcon />
                </div>
              </div>
            </div>
            <div className={classNames({ product_card_title_container: true })}>
              <div className={classNames({ product_card_title_logo: true })}>
                <div style={{ backgroundImage: `url(${brandImgUrl})` }} className={classNames({ image: true })} />
              </div>
              <div className={classNames({ product_card_title_text: true })}>
                <div
                  className={classNames({
                    product_card_title_text_brand_wrapper: true,
                  })}
                >
                  <p
                    className={classNames({
                      product_card_title_text_brand: true,
                    })}
                  >
                    {productBrand} {productModel}
                  </p>
                </div>
                <p
                  className={classNames({
                    product_card_title_text_model: true,
                  })}
                >
                  {commercialNameGrade}
                </p>
                {projectMode && product.project_mode && <div className="project_mode_label">{texts.project}</div>}
              </div>
            </div>
            <div className={classNames({ product_card_score_panel: true })}>
              <ScorePanel
                scorePanel={scorePanel}
                size="compact"
                maxProductScore={environment.config.max_product_score}
                projectMode={projectMode && product.project_mode}
              />
            </div>
          </div>
        )}
        {stickyVersion === true && (
          <div className={classNames({ product_card_small: true })} style={{ width, marginRight: margin }}>
            <div className={classNames({ product_card_small_head: true })}>
              <div className={classNames({ first_row: true })}>
                <div
                  className={classNames({ product_image_small: true })}
                  style={{ backgroundImage: `url(${thumbUrl})` }}
                >
                  {projectMode && product.project_mode && <div className="project_mode_overlay" />}
                </div>
                <div className={classNames({ product_buttons_small: true })}>
                  <div
                    className={classNames({
                      card_btn: true,
                      reference_btn: true,
                      active: isDeltaActive(),
                    })}
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      onDeltaClicked()
                    }}
                    onKeyPress={() => {
                      onDeltaClicked()
                    }}
                  >
                    {isDeltaActive() && <HearthStickyIconActive />}
                    {!isDeltaActive() && <HearthStickyIcon />}
                  </div>
                  <div
                    className={classNames({
                      card_btn: true,
                      edit_btn: true,
                    })}
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      onGoToScoringClicked(id)
                    }}
                    onKeyPress={() => {
                      onGoToScoringClicked(id)
                    }}
                  >
                    {authentication.user.parsedPermissions.canEditProduct &&
                      !product.is_locked &&
                      authentication.user.parsedPermissions.canViewProductScoring && <EditScoringStickyIcon />}

                    {(!authentication.user.parsedPermissions.canEditProduct || product.is_locked) && <EyeStickyIcon />}
                  </div>
                  <div
                    className={classNames({
                      card_btn: true,
                      remove_btn: true,
                    })}
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      onRemoveScoringClicked(id)
                    }}
                    onKeyPress={() => {
                      onRemoveScoringClicked(id)
                    }}
                  >
                    <RemoveStickyIcon />
                  </div>
                  <div className={classNames({ drag_btn_small: true })}>
                    <DragAndDropSmallIcon />
                  </div>
                </div>
              </div>
              <div className={classNames({ product_title_small: true })}>
                <p
                  className={classNames({
                    product_title_small_brand: true,
                  })}
                >
                  {productBrand}
                </p>
                <p
                  className={classNames({
                    product_title_small_model: true,
                  })}
                >
                  {productModel}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    environment: state.environment,
    authentication: state.authentication,
  }
}

export default connect(mapStateToProps, actionCreators)(ComparisonHeadItem)
