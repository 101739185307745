import React from 'react'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import _ from 'lodash'
import { getComparisonPopupElementWidth } from '../../../../../va-corejs-v3/utils'

import * as actionCreators from '../../../../store/actions'
import commentFeedbackMap from '../../../scoring_tree/helper/commentFeedbackMap'
import nodeDefinitionTypeMap from '../../../scoring_tree/helper/nodeDefinitionTypeMap'
import './styles.scss'
import ComparisonCommentsList from './_parts/comments_list'
import ComparisonMediaCarousel from './_parts/media_carousel'
import screenTypesMap from '../../../../layout/_parts/screenTypesMap'
import Score from '../../../commons/score'

const classNames = require('classnames')
const uuidv4 = require('uuid/v4')

export class ComparisonPopupBlock extends React.Component {
  constructor(props) {
    super(props)

    this.directions = {
      next: 'next',
      prev: 'prev',
    }

    this.state = {
      syncPhoto: false,
      syncPhotoScrolledIdx: 0,
      syncMediaIdx: 0,
      navigationList: {},
    }
  }

  componentDidMount = async () => {
    const { comparisonBoard } = this.props
    const { syncMedia } = comparisonBoard

    const navigationList = this.generateNavigationList()
    this.setState({ navigationList, syncPhoto: syncMedia })
  }

  componentWillUnmount = () => {
    this.setState = () => {}
  }

  handleUpdatesyncPhotoScrolledIdx = scrolledIdx => {
    this.setState({ syncPhotoScrolledIdx: scrolledIdx })
  }

  handleUpdateSyncMediaIdx = index => {
    this.setState({ syncMediaIdx: index })
  }

  handleCloseClicked = () => {
    const { updateNodePopup } = this.props
    updateNodePopup({
      visible: false,
      type: null,
      nodeDefId: 0,
    })
  }

  handleSyncPhotoClicked = () => {
    const { syncPhoto } = this.state
    const { updateSyncMediaForComparison } = this.props

    const newValue = !syncPhoto

    this.setState({
      syncPhoto: newValue,
      syncPhotoScrolledIdx: 0,
    })

    updateSyncMediaForComparison(newValue)
  }

  generateNavigationList = () => {
    const { comparisonBoard } = this.props
    const { comparedProducts } = comparisonBoard
    const fstProd = comparedProducts[0]
    const { tree } = fstProd
    const { nodes } = tree
    const nodeKeys = Object.keys(nodes)

    const items = []
    const criterion = []

    for (let i = 0; i !== nodeKeys.length; i += 1) {
      const key = nodeKeys[i]
      const node = nodes[key]
      const { type } = node

      if (type === nodeDefinitionTypeMap.item) items.push(key)
      else if (type === nodeDefinitionTypeMap.criterion) criterion.push(key)
    }

    return { items, criterion }
  }

  renderBreadCrumb = () => {
    const { comparisonBoard } = this.props
    const { nodePopup } = comparisonBoard
    const { nodeDefId } = nodePopup

    const { comparedProducts } = comparisonBoard
    const html = []

    if (comparedProducts.length > 0) {
      const prod = comparedProducts[0]
      const { tree } = prod
      const { nodes } = tree
      const node = nodes[nodeDefId]
      const { breadcrumb } = node

      if (!breadcrumb) return false

      for (let i = 0; i !== breadcrumb.length; i += 1) {
        const bNode = breadcrumb[i]
        html.push(
          <span key={uuidv4()} className={classNames({ breadcrumb_itm: true })}>
            {bNode}
          </span>
        )
      }
    }

    return html
  }

  goToNode = direction => {
    const { navigationList } = this.state
    const { comparisonBoard, updateNodePopup } = this.props
    const { nodePopup } = comparisonBoard
    const { type, nodeDefId } = nodePopup
    let navList = []
    if (type === nodeDefinitionTypeMap.item) {
      navList = navigationList.items
    } else if (type === nodeDefinitionTypeMap.criterion) {
      navList = navigationList.criterion
    }

    const nodeIdx = navList.indexOf(nodeDefId)
    const next = navList[nodeIdx + 1] || null
    const prev = navList[nodeIdx - 1] || null

    if (direction === this.directions.next && next !== null) {
      const duplicatedNodePopup = { ...nodePopup }
      duplicatedNodePopup.nodeDefId = next
      updateNodePopup(duplicatedNodePopup)
    } else if (direction === this.directions.prev && prev !== null) {
      const duplicatedNodePopup = { ...nodePopup }
      duplicatedNodePopup.nodeDefId = prev
      updateNodePopup(duplicatedNodePopup)
    }
  }

  renderMedia = () => {
    const { comparisonBoard } = this.props
    const { comparedProducts, order } = comparisonBoard
    const { syncPhotoScrolledIdx, syncMediaIdx } = this.state

    const html = []

    if (comparedProducts.length > 0) {
      for (let i = 0; i !== order.length; i += 1) {
        const isForecast = order[i].scoreSet === 'forecast'
        const prod = comparedProducts.find(item => {
          return item.id === order[i].id
        })
        const { tree } = prod
        html.push(
          <ComparisonMediaCarousel
            key={`mc_${i}`}
            data={tree}
            isForecast={isForecast}
            syncPhotoScrolledIdx={syncPhotoScrolledIdx}
            syncMediaIdx={syncMediaIdx}
            handleUpdateSyncMediaIdx={this.handleUpdateSyncMediaIdx}
            handleUpdatesyncPhotoScrolledIdx={this.handleUpdatesyncPhotoScrolledIdx}
          />
        )
      }
    }

    return html
  }

  renderComments = () => {
    const { comparisonBoard } = this.props
    const { comparedProducts, order } = comparisonBoard

    const html = []

    if (order.length > 0) {
      for (let i = 0; i !== order.length; i += 1) {
        const isForecast = order[i].scoreSet === 'forecast'
        const prod = comparedProducts.find(item => {
          return item.id === order[i].id
        })
        const { tree } = prod

        html.push(<ComparisonCommentsList key={uuidv4()} data={tree} isForecast={isForecast} />)
      }
    }

    return html
  }

  drawTooltipComments = comments => {
    const html = []
    for (let i = 0; i !== comments.length; i += 1) {
      const comment = comments[i]
      const { feedback, text } = comment
      const fback = feedback === null ? commentFeedbackMap.neutral : feedback
      html.push(
        <div key={uuidv4()} className={classNames({ comment_item: true })}>
          <div className={classNames({ comment_icon: true }, `comment_${fback}`)} />
          <span>{text}</span>
        </div>
      )
    }
    return html
  }

  renderCriterionRow = cID => {
    const { comparisonBoard, device } = this.props
    const { comparedProducts, order } = comparisonBoard
    const { size } = device
    const { innerWidth } = size
    const itemSize = getComparisonPopupElementWidth(innerWidth, order.length)
    const { itemWidth, marginBetweenItems } = itemSize

    const html = []
    for (let i = 0; i !== order.length; i += 1) {
      const isForecast = order[i].scoreSet === 'forecast'
      const prod = comparedProducts.find(item => {
        return item.id === order[i].id
      })
      const { id, tree } = prod
      const { nodes } = tree
      const cNode = nodes[cID]

      const { name, score, comments, media } = cNode

      // exclude comment forecast
      const commentsFiltered = isForecast ? comments.filter(c => c.is_forecast) : comments.filter(c => !c.is_forecast)
      const sortedComments = _.orderBy(commentsFiltered, 'order')

      if (i === 0) {
        html.push(
          <div key={uuidv4()} className={classNames({ name: true })}>
            {name.en}
          </div>
        )
      }

      const tooltipID = `comments_${id}_${cID}`

      html.push(
        <div
          className={classNames({ info: true })}
          style={{ width: itemWidth, marginRight: marginBetweenItems }}
          key={uuidv4()}
        >
          <Score score={score} classes="cscore" isDefault={cNode.is_default} />
          <span className={classNames({ mcount: true })}>
            <svg width="19" height="16" viewBox="0 0 19 16">
              <path
                fill="#BAB9B9"
                d="M2.972 12.145V5.858c0-1.988 1.04-3.04 3.003-3.04l9.107.007v-.169c0-1.425-.73-2.156-2.157-2.156H2.664C1.224.5.5 1.231.5 2.678v7.288c0 1.448.724 2.179 2.157 2.179h.315zM16.343 15.5c1.426 0 2.157-.731 2.157-2.178V5.968c0-1.44-.73-2.179-2.157-2.179H6.082c-1.44 0-2.164.731-2.164 2.179v7.354c0 1.447.724 2.178 2.164 2.178h10.261zM8.74 9.594c-.867 0-1.569-.724-1.569-1.601 0-.878.702-1.608 1.57-1.608.852 0 1.562.73 1.562 1.608 0 .877-.71 1.6-1.563 1.6zM16.38 14.3H6.039c-.595 0-.939-.35-.939-.957v-.563l1.19-1.104c.58-.534.924-.892 1.383-.892.473 0 .86.373 1.454.892l.667.607 2.228-2.047c.71-.658 1.19-1.097 1.763-1.097.58 0 1.075.432 1.777 1.097l1.756 1.703v1.404c0 .614-.351.957-.939.957z"
              />
            </svg>
            <span>{media.length}</span>
          </span>
          <span
            className={classNames({
              ccount: true,
              with_tooltip: sortedComments.length > 0,
            })}
            data-tip
            data-for={sortedComments.length > 0 ? tooltipID : null}
          >
            <svg width="17" height="17" viewBox="0 0 17 17">
              <path
                fill="#BAB9B9"
                d="M3.46 13.071c-1.864 0-2.96-1.15-2.96-2.999V3.446C.5 1.591 1.596.5 3.46.5h10.088C15.344.5 16.5 1.59 16.5 3.446v6.626c0 1.84-1.133 3-2.9 3h-.529v2.498c0 .568-.34.93-.933.93-.408 0-.709-.172-1.195-.56l-3.586-2.869z"
              />
            </svg>
            <span>{sortedComments.length}</span>
          </span>
          {sortedComments.length > 0 && (
            <ReactTooltip id={tooltipID} place="top">
              {this.drawTooltipComments(sortedComments)}
            </ReactTooltip>
          )}
        </div>
      )
    }
    return html
  }

  renderCriterion = () => {
    const { comparisonBoard } = this.props
    const { comparedProducts, nodePopup } = comparisonBoard
    const { nodeDefId } = nodePopup
    const fProd = comparedProducts[0]
    const { tree } = fProd
    const { nodes } = tree
    const node = nodes[nodeDefId]

    const criterionIds = node.children_ids

    const html = []

    // Fixed excluding null condition
    // TO DO: check why criterionIds is null

    if (!criterionIds) return false

    for (let i = 0; i !== criterionIds.length; i += 1) {
      const cID = criterionIds[i]
      html.push(
        <div key={uuidv4()} className={classNames({ criterion_row: true })}>
          {this.renderCriterionRow(cID)}
        </div>
      )
    }

    return html
  }

  render() {
    const { comparisonBoard, device, texts } = this.props
    const { nodePopup } = comparisonBoard

    const { nodeDefId } = nodePopup

    const { type } = nodePopup
    const { syncPhoto } = this.state
    return (
      <div
        className={classNames({
          comparison_popup_outer: true,
          isSafari: device.isSafari,
          isFirefox: device.isFirefox,
          desk: device.screenType === screenTypesMap.desk,
          ipadh: device.screenType === screenTypesMap.iPadH,
          ipadv: device.screenType === screenTypesMap.iPadV,
          iphonexr: device.screenType === screenTypesMap.iPhoneXR,
          iphonese: device.screenType === screenTypesMap.iPhoneSE,
        })}
      >
        <div className={classNames({ comparison_popup_inner: true })}>
          <div className={classNames({ comparison_popup_header: true })}>
            <div className={classNames({ breadcrumb: true })}>{this.renderBreadCrumb()}</div>

            <div className={classNames({ sync_btn: true })}>
              <div className={classNames({ sync_text: true })}>{texts.sync_photo_scroll}</div>
              <label htmlFor="sync-photo-scroll" className={classNames({ ios_toggle: true })}>
                <input
                  id="sync-photo-scroll"
                  type="checkbox"
                  checked={syncPhoto}
                  onChange={() => {
                    this.handleSyncPhotoClicked()
                  }}
                />
                <span className={classNames({ slider: true })} />
              </label>
            </div>

            <div
              className={classNames({ close: true })}
              role="button"
              tabIndex={0}
              onClick={() => {
                this.handleCloseClicked()
              }}
              onKeyPress={() => {
                this.handleCloseClicked()
              }}
            >
              <svg width="12" height="12" viewBox="0 0 12 12">
                <path
                  fill="#fff"
                  fillRule="evenodd"
                  d="M1.718.293L6 4.575 10.282.293c.39-.39 1.023-.39 1.414 0l.01.011c.391.39.391 1.024 0 1.414L7.426 6l4.282 4.282c.39.39.39 1.023 0 1.414l-.011.01c-.39.391-1.024.391-1.414 0L6 7.426l-4.282 4.282c-.39.39-1.023.39-1.414 0l-.01-.011c-.391-.39-.391-1.024 0-1.414L4.574 6 .293 1.718c-.39-.39-.39-1.023 0-1.414l.011-.01c.39-.391 1.024-.391 1.414 0z"
                />
              </svg>
            </div>
          </div>
          <div className={classNames({ comparison_popup_navigator: true })}>
            <div
              className={classNames({ prev_node: true })}
              role="button"
              tabIndex={0}
              onClick={() => {
                this.goToNode(this.directions.prev)
              }}
              onKeyPress={() => {
                this.goToNode(this.directions.prev)
              }}
            >
              <svg width="15" height="26" viewBox="0 0 15 26">
                <path
                  fill="#FFF"
                  d="M13.252 26c-.485 0-.882-.176-1.22-.498L.602 14.363C.206 13.953 0 13.528 0 13c0-.513.191-.967.602-1.348L12.032.498C12.37.176 12.767 0 13.252 0 14.236 0 15 .762 15 1.73c0 .468-.206.923-.529 1.26L4.172 13.015l10.3 9.995c.337.352.528.777.528 1.26 0 .968-.764 1.73-1.748 1.73z"
                />
              </svg>
            </div>
            <div
              className={classNames({ next_node: true })}
              role="button"
              tabIndex={0}
              onClick={() => {
                this.goToNode(this.directions.next)
              }}
              onKeyPress={() => {
                this.goToNode(this.directions.next)
              }}
            >
              <svg width="15" height="26" viewBox="0 0 15 26">
                <path
                  fill="#FFF"
                  d="M1.748 26c.485 0 .882-.176 1.22-.498l11.43-11.139c.396-.41.602-.835.602-1.363 0-.513-.191-.967-.602-1.348L2.968.498C2.63.176 2.233 0 1.748 0 .764 0 0 .762 0 1.73c0 .468.206.923.529 1.26l10.299 10.025-10.3 9.995c-.337.352-.528.777-.528 1.26C0 25.239.764 26 1.748 26z"
                />
              </svg>
            </div>
          </div>
          <div className={classNames({ comparison_popup_media: true })} key={nodeDefId}>
            {this.renderMedia()}
          </div>
          <div className={classNames({ comparison_popup_comments: true })}>{this.renderComments()}</div>
          {type === nodeDefinitionTypeMap.item && (
            <div className={classNames({ comparison_popup_criterion: true })}>{this.renderCriterion()}</div>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    device: state.device,
    texts: state.texts.values,
    environment: state.environment,
    authentication: state.authentication,
    comparisonBoard: state.comparisonBoard,
  }
}

export default connect(mapStateToProps, actionCreators)(ComparisonPopupBlock)
