import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { getComparisonPopupElementWidth } from '../../../../../../va-corejs-v3/utils'

import * as actionCreators from '../../../../../store/actions'
import commentFeedbackMap from '../../../../scoring_tree/helper/commentFeedbackMap'

const classNames = require('classnames')
const uuidv4 = require('uuid/v4')

export class ComparisonCommentsList extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      comments: [],
    }
  }

  componentDidMount = async () => {
    const { data, isForecast, comparisonBoard } = this.props

    const { nodePopup } = comparisonBoard
    const { nodeDefId } = nodePopup

    const { nodes } = data
    const node = nodes[nodeDefId]
    const { comments } = node

    const commentsFiltered = isForecast ? comments.filter(c => c.is_forecast) : comments.filter(c => !c.is_forecast)
    const sortedComments = _.orderBy(commentsFiltered, 'order')

    this.setState({ loading: false, comments: sortedComments })
  }

  componentWillUnmount = () => {
    this.setState = () => {}
  }

  drawComments = () => {
    const { device, comparisonBoard } = this.props
    const { order } = comparisonBoard

    const { comments } = this.state

    const { size } = device
    const { innerWidth } = size
    const itemSize = getComparisonPopupElementWidth(innerWidth, order.length)
    const { itemWidth } = itemSize
    const staticPadding = 18 + 24
    const staticMargin = 15

    const html = []

    for (let i = 0; i !== comments.length; i += 1) {
      const comment = comments[i]
      const { feedback, text } = comment
      const fback = feedback === null ? commentFeedbackMap.neutral : feedback
      html.push(
        <div
          key={uuidv4()}
          className={classNames({ comment_item: true })}
          style={{
            width: itemWidth - staticPadding - staticMargin,
          }}
        >
          <div className={classNames({ comment_icon: true }, `comment_${fback}`)} />
          <span>{text}</span>
        </div>
      )
    }
    return html
  }

  render() {
    const { device, comparisonBoard } = this.props
    const { order } = comparisonBoard
    const { loading, comments } = this.state

    const { size } = device
    const { innerWidth } = size
    const itemSize = getComparisonPopupElementWidth(innerWidth, order.length)
    const { itemWidth, marginBetweenItems } = itemSize

    return (
      <>
        {loading === false && comments.length > 0 && (
          <div
            key={uuidv4()}
            className={classNames({ comments_list: true })}
            style={{ width: itemWidth, marginRight: marginBetweenItems }}
          >
            {this.drawComments()}
          </div>
        )}
        {loading === false && comments.length === 0 && (
          <div
            key={uuidv4()}
            className={classNames({ empty_comments_list: true })}
            style={{ width: itemWidth, marginRight: marginBetweenItems }}
          />
        )}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    device: state.device,
    texts: state.texts.values,
    environment: state.environment,
    authentication: state.authentication,
    comparisonBoard: state.comparisonBoard,
  }
}

export default connect(mapStateToProps, actionCreators)(ComparisonCommentsList)
