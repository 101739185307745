import React from 'react'
import { connect } from 'react-redux'
import DragSortableList from 'react-drag-sortable'

import * as actionCreators from '../../../../store/actions'
import ComparisonActionPanel from './_parts/actions'
import ComparisonHeadItem from './_parts/item'
import scoringModeMap from '../../../scoring_tree/helper/scoringModeMap'

import './styles.scss'
import { getComparisonElementWidth } from '../../../../../va-corejs-v3/utils'

const classNames = require('classnames')

export class ComparisonHeadBlock extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      deltaValue: null,
    }
  }

  componentDidMount = async () => {
    const { comparisonBoard } = this.props
    const { deltaProduct } = comparisonBoard
    this.setState({ deltaValue: deltaProduct })
  }

  handleGoToScoringClicked = id => {
    const { authentication } = this.props
    const view = authentication.user.parsedPermissions.canViewProductScoring ? scoringModeMap.scoring : 1
    const redirectTo = `/scoring?id=${id}&view=${view}`
    if (typeof window !== 'undefined') {
      window.location.href = redirectTo
    }
  }

  handleRemoveScoringClicked = async id => {
    const { removeProductFromComparedProducts } = this.props
    await removeProductFromComparedProducts(id)
  }

  handleDeltaClicked = async value => {
    const { updateDeltaProduct } = this.props
    const { deltaValue } = this.state

    const newDeltaValue =
      value.id === deltaValue.id && value.scoreSet === deltaValue.scoreSet
        ? {
            id: null,
            scoreSet: null,
          }
        : value
    this.setState({ deltaValue: newDeltaValue })
    await updateDeltaProduct(newDeltaValue)
  }

  onSort = async sortedList => {
    const { updateSortedElements, comparisonBoard } = this.props
    const { comparedProducts } = comparisonBoard

    function findProductById(id) {
      let product = {}
      for (let i = 0; i !== comparedProducts.length; i += 1) {
        const prod = comparedProducts[i]
        if (prod.id === id) {
          product = prod
          break
        }
      }
      return product
    }

    const sortedElements = []
    for (let i = 0; i !== sortedList.length; i += 1) {
      const { content } = sortedList[i]
      const { props } = content
      const { id, projectMode } = props
      const product = findProductById(id)
      sortedElements.push({
        element: product,
        scoreSet: projectMode ? 'forecast' : 'benchmark',
      })
    }

    await updateSortedElements(sortedElements)
  }

  parseComparedProductsForRender() {
    const { comparisonBoard, device, stickyVersion } = this.props
    const { deltaValue } = this.state
    const { comparedProducts, order } = comparisonBoard

    const { size } = device
    const { innerWidth } = size
    const itemSize = getComparisonElementWidth(innerWidth, order.length)
    const { marginBetweenItems, itemWidth } = itemSize

    const list = []
    for (let i = 0; i !== order.length; i += 1) {
      const isCurrentElementProject = order[i].scoreSet === 'forecast'
      const product = comparedProducts.find(item => {
        return item.id === order[i].id
      })

      list.push({
        content: (
          <ComparisonHeadItem
            id={product.id}
            key={product.id}
            margin={marginBetweenItems}
            width={itemWidth}
            product={product}
            deltaValue={deltaValue}
            stickyVersion={stickyVersion}
            onGoToScoringClicked={this.handleGoToScoringClicked}
            onDeltaClicked={this.handleDeltaClicked}
            onRemoveScoringClicked={this.handleRemoveScoringClicked}
            projectMode={isCurrentElementProject}
          />
        ),
      })
    }
    return list
  }

  render() {
    const { stickyVersion, expandCollapseStatus, onExpandCollape } = this.props
    const placeholder = (
      <div
        className={classNames({
          comparison_board_drop_placeholder: true,
        })}
      >
        <span> Drop Product Here </span>
      </div>
    )
    const list = this.parseComparedProductsForRender()

    return (
      <div className={classNames({ comparison_head: true })}>
        <div
          className={classNames({
            comparison_left: true,
            sticky_enabled: stickyVersion,
          })}
        >
          <ComparisonActionPanel expandCollapseStatus={expandCollapseStatus} onExpandCollape={onExpandCollape} />
        </div>
        <div
          className={classNames({
            comparison_right: true,
            sticky_enabled: stickyVersion,
          })}
        >
          <DragSortableList
            items={list}
            placeholder={placeholder}
            onSort={this.onSort}
            dropBackTransitionDuration={0.3}
            type="horizontal"
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    device: state.device,
    texts: state.texts.values,
    environment: state.environment,
    authentication: state.authentication,
    comparisonBoard: state.comparisonBoard,
  }
}

export default connect(mapStateToProps, actionCreators)(ComparisonHeadBlock)
