import React from 'react'
import { connect } from 'react-redux'

import { SortingSelector } from 'searchkit'
import * as actionCreators from '../../../store/actions'

const classNames = require('classnames')

export class SearchSort extends React.Component {
  render() {
    const { texts } = this.props
    const optionList = [
      {
        label: texts.sort_by_date_created_desc,
        field: 'created_at',
        order: 'desc',
      },
      {
        label: texts.sort_by_date_created_asc,
        field: 'created_at',
        order: 'asc',
      },
      {
        label: texts.sort_by_az,
        fields: [
          { field: 'props.brand.value', options: { order: 'asc' } },
          { field: 'props.model.value', options: { order: 'asc' } },
        ],
        key: 'az_asc',
      },
      {
        label: texts.sort_by_za,
        fields: [
          { field: 'props.brand.value', options: { order: 'desc' } },
          { field: 'props.model.value', options: { order: 'desc' } },
        ],
        key: 'az_desc',
      },
    ]

    if (JSON.parse(process.env.sortByGlobalScoreEnabled) === true) {
      optionList.push({
        label: texts.sort_by_score_high_low,
        field: 'global_score',
        order: 'desc',
      })
      optionList.push({
        label: texts.sort_by_score_low_hight,
        field: 'global_score',
        order: 'asc',
      })
    }
    return (
      <div className={classNames({ search_sort: true, theme_search_sort: true })}>
        <div className={classNames({ search_sort_label: true })}>{texts.sort_by}</div>
        <SortingSelector className={classNames({ theme_sk_select_color: true })} options={optionList} />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    environment: state.environment,
    device: state.device,
    texts: state.texts.values,
    scoring: state.scoring,
    scoringTree: state.scoringTree,
  }
}

export default connect(mapStateToProps, actionCreators)(SearchSort)
