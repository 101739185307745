import React from 'react'
import { connect } from 'react-redux'

import _ from 'lodash'
import * as actionCreators from '../../../../store/actions'
import nodeDefinitionTypeMap from '../../../scoring_tree/helper/nodeDefinitionTypeMap'
import './styles.scss'

import ExpandIcon from '../../../../assets/svg/expand-icon.svg'
import CollapseIcon from '../../../../assets/svg/collapse-icon.svg'
import MediaCountIcon from '../../../../assets/svg/media-count-icon.svg'
import CommentsCountIcon from '../../../../assets/svg/comments-count-icon.svg'
import expandCollapseStatusMap from '../expandCollapseStatusMap'
import { getComparisonElementWidth } from '../../../../../va-corejs-v3/utils'
import Score from '../../../commons/score'
import mediaCollectionsMap from '../../../../../va-corejs-v3/utils/mediaCollectionsMap'
import { isNodeEligibleForCal } from '../../../../../va-corejs-v3/specializations'
import ComparisonNodeMedia from './nodeMedia'

const classNames = require('classnames')
const uuidv4 = require('uuid/v4')

export class ComparisonBodyBlock extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      initialized: false,
      defs: {},
      perimeters: [],
      expanded: [],
    }
  }

  componentDidMount = () => {
    const { comparisonBoard } = this.props
    const { referenceTemplate } = comparisonBoard
    const defs = referenceTemplate.node_definitions

    const perimeters = []
    const expanded = []
    const keys = Object.keys(defs)
    for (let i = 0; i !== keys.length; i += 1) {
      const key = keys[i]
      const nDef = defs[key]
      const { id, type } = nDef
      if (type === nodeDefinitionTypeMap.perimeter) {
        perimeters.push(id)
      }
    }
    this.setState({ initialized: true, defs, perimeters, expanded })
  }

  componentDidUpdate = prevProps => {
    const { expandCollapseStatus } = this.props

    if (expandCollapseStatus !== prevProps.expandCollapseStatus) {
      if (expandCollapseStatus === expandCollapseStatusMap.expanded) {
        this.expandAll()
      } else {
        this.collapseAll()
      }
    }
  }

  expandAll = () => {
    const { defs } = this.state
    const keys = Object.keys(defs)
    const newExpanded = []
    for (let i = 0; i !== keys.length; i += 1) {
      const key = keys[i]
      const nDef = defs[key]
      const { id, type } = nDef
      if (type !== nodeDefinitionTypeMap.criterion) {
        newExpanded.push(id)
      }
    }
    this.setState({ expanded: newExpanded })
  }

  collapseAll = () => {
    this.setState({ expanded: [] })
  }

  expandCollapseNode = id => {
    const { expanded } = this.state
    const duplicatedExpanded = [...expanded]
    const index = duplicatedExpanded.indexOf(id)
    if (index < 0) {
      duplicatedExpanded.push(id)
    } else {
      duplicatedExpanded.splice(index, 1)
    }
    this.setState({ expanded: duplicatedExpanded })
  }

  renderItemThumb = media => {
    const html = []
    html.push(<ComparisonNodeMedia media={media.length > 0 ? media[0] : null} />)
    return html
  }

  handleNodeClicked = async (nodeDefId, nodeType) => {
    const { updateNodePopup } = this.props
    await updateNodePopup({
      visible: true,
      type: nodeType,
      nodeDefId,
    })
  }

  calcBestWorst = id => {
    const { comparisonBoard } = this.props
    const { comparedProducts, order } = comparisonBoard

    let sortByScoreList = []
    for (let i = 0; i !== order.length; i += 1) {
      const isForecast = order[i].scoreSet === 'forecast'
      const prod = comparedProducts.find(item => {
        return item.id === order[i].id
      })
      const { tree } = prod
      const { nodes } = tree
      const node = nodes[id]
      const isNodeEnabled = this.isNodeEnabled(node, isForecast)

      if (node && isNodeEnabled) {
        const normalizedScore = this.getNodeAttribute(node, 'normalizedScore', isForecast)
        const fixedNormalizedScore = normalizedScore.toFixed(0)

        if (sortByScoreList[fixedNormalizedScore] === undefined) {
          sortByScoreList[fixedNormalizedScore] = {
            score: fixedNormalizedScore,
            products: [],
          }
        }

        sortByScoreList[fixedNormalizedScore].products.push({
          id: prod.id,
          scoreSet: order[i].scoreSet,
        })
      }
    }

    sortByScoreList = sortByScoreList.filter(item => {
      return item !== undefined
    })

    return sortByScoreList
  }

  getBestWorstClasses = list => {
    const classes = []

    switch (list.length) {
      case 1:
        list[0].products.forEach(item => {
          classes.push({
            id: item.id,
            scoreSet: item.scoreSet,
            className: 'none',
          })
        })
        break
      case 2:
        list[0].products.forEach(item => {
          classes.push({
            id: item.id,
            scoreSet: item.scoreSet,
            className: 'last',
          })
        })
        list[1].products.forEach(item => {
          classes.push({
            id: item.id,
            scoreSet: item.scoreSet,
            className: '1',
          })
        })
        break
      case 3:
        list[0].products.forEach(item => {
          classes.push({
            id: item.id,
            scoreSet: item.scoreSet,
            className: 'last',
          })
        })
        list[1].products.forEach(item => {
          classes.push({
            id: item.id,
            scoreSet: item.scoreSet,
            className: '2',
          })
        })
        list[2].products.forEach(item => {
          classes.push({
            id: item.id,
            scoreSet: item.scoreSet,
            className: '1',
          })
        })
        break
      case 4:
        list[0].products.forEach(item => {
          classes.push({
            id: item.id,
            scoreSet: item.scoreSet,
            className: 'last',
          })
        })
        list[1].products.forEach(item => {
          classes.push({
            id: item.id,
            scoreSet: item.scoreSet,
            className: '3',
          })
        })
        list[2].products.forEach(item => {
          classes.push({
            id: item.id,
            scoreSet: item.scoreSet,
            className: '2',
          })
        })
        list[3].products.forEach(item => {
          classes.push({
            id: item.id,
            scoreSet: item.scoreSet,
            className: '1',
          })
        })
        break
      case 5:
        list[0].products.forEach(item => {
          classes.push({
            id: item.id,
            scoreSet: item.scoreSet,
            className: 'last',
          })
        })
        list[1].products.forEach(item => {
          classes.push({
            id: item.id,
            scoreSet: item.scoreSet,
            className: '4',
          })
        })
        list[2].products.forEach(item => {
          classes.push({
            id: item.id,
            scoreSet: item.scoreSet,
            className: '3',
          })
        })
        list[3].products.forEach(item => {
          classes.push({
            id: item.id,
            scoreSet: item.scoreSet,
            className: '2',
          })
        })
        list[4].products.forEach(item => {
          classes.push({
            id: item.id,
            scoreSet: item.scoreSet,
            className: '1',
          })
        })
        break
      default:
    }

    return classes
  }

  calcDelta = nodeId => {
    const { comparisonBoard } = this.props
    const { comparedProducts, order, deltaProduct } = comparisonBoard

    let refScore = 0
    const deltaProducts = []

    for (let i = 0; i !== order.length; i += 1) {
      const { scoreSet } = order[i]
      const prod = comparedProducts.find(item => {
        return item.id === order[i].id
      })
      const { id, tree } = prod
      const { nodes } = tree
      const node = nodes[nodeId]

      if (node) {
        const normalizedScore = this.getNodeAttribute(node, 'normalizedScore', scoreSet === 'forecast')
        const fixedNormalizedScore = normalizedScore.toFixed(0)

        if (id === deltaProduct.id && deltaProduct.scoreSet === scoreSet) {
          refScore = fixedNormalizedScore
        } else {
          deltaProducts.push({ id, scoreSet, fixedNormalizedScore })
        }
      }
    }

    const result = []
    for (let i = 0; i !== deltaProducts.length; i += 1) {
      const { id, scoreSet, fixedNormalizedScore } = deltaProducts[i]

      const delta = fixedNormalizedScore - refScore
      result.push({
        id,
        scoreSet,
        delta,
      })
    }

    return result
  }

  haveDifferentValues = (nodeId, type) => {
    const { comparisonBoard } = this.props
    const { comparedProducts, order } = comparisonBoard

    let foundDifference = false

    let lastValue = 0
    for (let i = 0; i !== order.length; i += 1) {
      const isForecast = order[i].scoreSet === 'forecast'
      const prod = comparedProducts.find(item => {
        return item.id === order[i].id
      })
      const { tree } = prod
      const { nodes } = tree
      const node = nodes[nodeId]
      if (node) {
        const normalizedScore = this.getNodeAttribute(node, 'normalizedScore', isForecast)
        const score = this.getNodeAttribute(node, 'score', isForecast)
        const fixedNormalizedScore = normalizedScore.toFixed(0)

        const valueToCheck = type === nodeDefinitionTypeMap.criterion ? score : fixedNormalizedScore

        if (i === 0) {
          lastValue = valueToCheck
        } else if (i > 0 && valueToCheck !== lastValue) {
          foundDifference = true
          break
        }
      }
    }

    return foundDifference
  }

  getNodeAttribute = (node, attribute, isForecast) => {
    if (isForecast && node.forecast !== undefined) {
      return node.forecast[attribute]
    }

    return node[attribute]
  }

  isNodeEnabled = (node, isForecast) => {
    const scoreSetType = isForecast ? 'forecast' : null
    const eligible = isNodeEligibleForCal(node, node, scoreSetType)
    return eligible
  }

  getComments = (node, isForecast) => {
    const { comments } = node

    if (isForecast) {
      return comments.filter(c => c.is_forecast)
    }

    return comments.filter(c => !c.is_forecast)
  }

  getMedia = (node, isForecast) => {
    const { media } = node

    if (isForecast) {
      return media.filter(m => m.collection === mediaCollectionsMap.forecast_node_pictures)
    }

    return media.filter(m => m.collection === mediaCollectionsMap.node_pictures)
  }

  drawNodeRow = (id, type) => {
    const { comparisonBoard, device } = this.props
    const { comparedProducts, bestWorstEnabled, deltaProduct, order } = comparisonBoard

    const { size } = device
    const { innerWidth } = size
    const itemSize = getComparisonElementWidth(innerWidth, order.length)
    const { itemWidth, marginBetweenItems } = itemSize

    const html = []
    const deltaList = deltaProduct !== null && deltaProduct.id !== null ? this.calcDelta(id) : []

    let bestWorstClasses = []
    if (bestWorstEnabled === true && type === nodeDefinitionTypeMap.item) {
      bestWorstClasses = this.getBestWorstClasses(this.calcBestWorst(id))
    }

    const showDeltaPercentage = JSON.parse(process.env.comparisonShowDeltaPercentage) === true
    for (let i = 0; i !== order.length; i += 1) {
      const { scoreSet } = order[i]
      const isForecast = scoreSet === 'forecast'

      const prod = comparedProducts.find(item => {
        return item.id === order[i].id
      })

      const bestWorstClass = _.find(bestWorstClasses, item => {
        return item.id === prod.id && item.scoreSet === order[i].scoreSet
      })

      const { tree } = prod
      const { nodes } = tree
      const node = nodes[id]

      if (node) {
        const score = this.getNodeAttribute(node, 'score', isForecast)
        const normalizedScore = this.getNodeAttribute(node, 'normalizedScore', isForecast)
        const percentage = this.getNodeAttribute(node, 'percentage', isForecast)
        const isNodeEnabled = this.isNodeEnabled(node, isForecast)
        const isNodeDefault = this.getNodeAttribute(node, 'is_default', isForecast)
        const comments = this.getComments(node, isForecast)
        const media = this.getMedia(node, isForecast)

        const fixedNormalizedScore = normalizedScore ? normalizedScore.toFixed(0) : 0
        const fixedPercentage = percentage ? percentage.toFixed(0) : 0

        const nodeClassNames = {
          node: true,
        }

        switch (type) {
          case nodeDefinitionTypeMap.perimeter:
          case nodeDefinitionTypeMap.family:
          case nodeDefinitionTypeMap.subfamily:
            if (isNodeEnabled) {
              const currentDelta = _.find(deltaList, item => {
                return item.id === prod.id && item.scoreSet === scoreSet
              })
              html.push(
                <div
                  className={classNames(nodeClassNames)}
                  style={{
                    width: itemWidth,
                    marginRight: marginBetweenItems,
                  }}
                  key={uuidv4()}
                >
                  <span className={classNames({ node_score: true })}>{fixedNormalizedScore}</span>
                  {showDeltaPercentage === true && type !== nodeDefinitionTypeMap.perimeter && (
                    <span className={classNames({ node_percentage: true })}>{fixedPercentage}%</span>
                  )}
                  {currentDelta && (
                    <span
                      className={classNames({
                        delta_value: true,
                        negative: currentDelta.delta < 0,
                      })}
                    >
                      {currentDelta.delta}
                    </span>
                  )}
                </div>
              )
            } else {
              html.push(
                <div
                  className={classNames(nodeClassNames)}
                  style={{
                    width: itemWidth,
                    marginRight: marginBetweenItems,
                  }}
                  key={uuidv4()}
                />
              )
            }
            break
          case nodeDefinitionTypeMap.item:
            if (isNodeEnabled) {
              if (bestWorstClass !== undefined && bestWorstClass.className !== undefined) {
                nodeClassNames[`best_worst_${bestWorstClass.className}`] = true
              }

              const currentDelta = _.find(deltaList, item => {
                return item.id === prod.id && item.scoreSet === scoreSet
              })

              html.push(
                <span
                  className={classNames(nodeClassNames, type)}
                  style={{
                    width: i !== 0 ? itemWidth : itemWidth - 30,
                    marginRight: marginBetweenItems,
                  }}
                  key={uuidv4()}
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    this.handleNodeClicked(id, type)
                  }}
                  onKeyPress={() => {
                    this.handleNodeClicked(id, type)
                  }}
                >
                  {this.renderItemThumb(media)}
                  <div className={classNames({ normalized_value: true })}>
                    <span className={classNames({ node_score: true })}>{fixedNormalizedScore}</span>
                    {showDeltaPercentage === true && (
                      <span className={classNames({ node_percentage: true })}>{fixedPercentage}%</span>
                    )}
                    {currentDelta && (
                      <span
                        className={classNames({
                          delta_value: true,
                          negative: currentDelta.delta < 0,
                        })}
                      >
                        {currentDelta.delta}
                      </span>
                    )}
                  </div>
                  <span className={classNames({ count_container: true })}>
                    <span className={classNames({ itm_media_count: true })}>
                      <MediaCountIcon />
                      <span className={classNames({ itm_media_count_value: true })}>{media.length}</span>
                    </span>
                    <span className={classNames({ itm_comments_count: true })}>
                      <CommentsCountIcon />
                      <span
                        className={classNames({
                          itm_comments_count_value: true,
                        })}
                      >
                        {comments.length}
                      </span>
                    </span>
                  </span>
                </span>
              )
            } else {
              html.push(
                <span
                  className={classNames(nodeClassNames, type)}
                  style={{
                    width: i !== 0 ? itemWidth : itemWidth - 30,
                    marginRight: marginBetweenItems,
                  }}
                  key={uuidv4()}
                />
              )
            }
            break
          default:
            if (isNodeEnabled) {
              html.push(
                <span
                  className={classNames(nodeClassNames, type)}
                  style={{
                    width: i !== 0 ? itemWidth - 10 : itemWidth - 50,
                    marginRight: marginBetweenItems,
                  }}
                  key={uuidv4()}
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    this.handleNodeClicked(id, type)
                  }}
                  onKeyPress={() => {
                    this.handleNodeClicked(id, type)
                  }}
                >
                  <Score score={score} classes="score_value" isDefault={isNodeDefault} />
                  <span className={classNames({ count_container: true })}>
                    <span className={classNames({ criterion_media_count: true })}>
                      <MediaCountIcon />
                      <span
                        className={classNames({
                          criterion_media_count_value: true,
                        })}
                      >
                        {media.length}
                      </span>
                    </span>
                    <span className={classNames({ criterion_comments_count: true })}>
                      <CommentsCountIcon />
                      <span
                        className={classNames({
                          criterion_comments_count_value: true,
                        })}
                      >
                        {comments.length}
                      </span>
                    </span>
                  </span>
                </span>
              )
            } else {
              html.push(
                <span
                  className={classNames(nodeClassNames, type)}
                  style={{
                    width: i !== 0 ? itemWidth - 10 : itemWidth - 50,
                    marginRight: marginBetweenItems,
                  }}
                  key={uuidv4()}
                />
              )
            }
        }
      }
    }
    return html
  }

  recursivelyDrawNodes = (idList, html) => {
    const { defs, expanded } = this.state

    const { comparisonBoard } = this.props
    const { differencesEnabled } = comparisonBoard

    for (let i = 0; i !== idList.length; i += 1) {
      const nId = idList[i]
      const nDef = defs[nId]
      const { name, type } = nDef

      const renderRow = differencesEnabled ? this.haveDifferentValues(nId, type) : true

      if (renderRow) {
        html.push(
          <div key={uuidv4()} id={nId} className={classNames({ c_row: true }, type)}>
            {type !== nodeDefinitionTypeMap.criterion && (
              <span className={classNames({ node_btn: true }, `node_${type}`)}>
                {expanded.indexOf(nId) >= 0 && <CollapseIcon />}
                {expanded.indexOf(nId) < 0 && <ExpandIcon />}
              </span>
            )}
            <span
              className={classNames({ node_name: true }, `node_${type}`)}
              role="button"
              tabIndex={0}
              onClick={() => {
                this.expandCollapseNode(nId)
              }}
              onKeyPress={() => {
                this.expandCollapseNode(nId)
              }}
            >
              {name.en}
            </span>
            <span
              className={classNames(
                {
                  comparison_node_data: true,
                  initial: type === nodeDefinitionTypeMap.perimeter && i === 0,
                  detailed: type === nodeDefinitionTypeMap.perimeter && i !== 0,
                },
                `node_${type}`
              )}
            >
              {this.drawNodeRow(nId, type)}
            </span>
          </div>
        )
        if (expanded.indexOf(nId) >= 0) {
          this.recursivelyDrawNodes(nDef.children_ids, html)
        }
      }
    }
  }

  renderRows = () => {
    const { perimeters } = this.state
    const rows = []
    this.recursivelyDrawNodes(perimeters, rows)
    return rows
  }

  render() {
    const { initialized } = this.state

    return (
      <div className={classNames({ comparison_body_block: true })}>
        {initialized === true && <div>{this.renderRows()}</div>}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    device: state.device,
    texts: state.texts.values,
    environment: state.environment,
    authentication: state.authentication,
    comparisonBoard: state.comparisonBoard,
  }
}

export default connect(mapStateToProps, actionCreators)(ComparisonBodyBlock)
