/* eslint-disable no-unused-vars */
import React from 'react'
import { connect } from 'react-redux'
import './styles.scss'

import { SearchkitComponent, SearchkitProvider, SearchBox, Hits, Pagination, TermQuery, BoolMust } from 'searchkit'
import _ from 'lodash'
import * as actionCreators from '../../../../store/actions'

import SearchFilter from '../../../search/_parts/filter'
import PaginationComponent from '../../../search/_parts/paginationComponent'
import SearchSort from '../../../search/_parts/sort'

import screenTypesMap from '../../../../layout/_parts/screenTypesMap'

import CheckWhite from '../../../../assets/svg/check-white.svg'

import ComparisonSearchHit from './comparisonSearchHit'
import { customQueryBuilder } from '../../../search/common/utils'
import searchContextMap from '../../../search/_parts/searchContextMap'

const classNames = require('classnames')

export class ComparisonSearchAddProducts extends SearchkitComponent {
  constructor(props) {
    super(props)
    this.validationStatusMap = {
      choosingProducts: 0,
      validating: 1,
      completed: 2,
    }

    this.state = {
      processState: this.validationStatusMap.choosingProducts,
      defaultQuery: {},
    }
  }

  initSearchKit = () => {
    const { initializeSearchkitManager, authentication } = this.props
    const { user } = authentication
    initializeSearchkitManager('valueable/', user, searchContextMap.comparison)
  }

  clearSearchKit = () => {
    const { clearSearchkitManager } = this.props
    clearSearchkitManager(searchContextMap.comparison)
  }

  componentDidMount = async () => {
    this.initSearchKit()

    const { comparisonBoard, updateChosenProductsForComparison } = this.props
    const { comparedProducts } = comparisonBoard
    await updateChosenProductsForComparison([...comparedProducts])
  }

  componentDidUpdate = async prevProps => {
    const { defaultQuery } = this.state
    const { comparisonBoard: prevComparisonBoard } = prevProps
    const {
      chosenProductsForComparison: prevChosenProductsForComparison,
      comparedProducts: prevComparedProducts,
    } = prevComparisonBoard

    const { comparisonBoard, searchkit, nodePopupIsLoading } = this.props
    const { chosenProductsForComparison, comparedProducts } = comparisonBoard

    if (prevChosenProductsForComparison !== chosenProductsForComparison || prevComparedProducts !== comparedProducts) {
      // Handle update only if the props have changed

      const choosenProducts = chosenProductsForComparison.length
      if (choosenProducts === 0 && comparedProducts.length === 0) {
        // If I have 0 product selected in search and no product rendered on comparison
        // I remove the template query from searchkit
        await nodePopupIsLoading(true)
        searchkit.addDefaultQuery(query => {
          // there is no easy way to remove filters/queries with searchkit
          const filtered = _.filter(query.index.queries, item => {
            if (item.bool && item.bool.must && item.bool.must.term && item.bool.must.term['template.name']) {
              return false
            }

            return true
          })
          query.index.queries = filtered
          return query
        })
        await searchkit.reloadSearch()
        nodePopupIsLoading(false)
      } else if (choosenProducts >= 1) {
        // If I have 1 or more products in choosen products I have to apply filter
        await nodePopupIsLoading(true)
        const firstProductSelected = chosenProductsForComparison[0]
        const { template } = firstProductSelected
        const { name } = template
        searchkit.addDefaultQuery(query => {
          return query.addQuery(BoolMust(TermQuery('template.name', name)))
        })
        await searchkit.reloadSearch()
        nodePopupIsLoading(false)
      }
    }
  }

  componentWillUnmount = async () => {
    this.setState = () => {}
    await this.clearSearchKit()
  }

  handleCancel = () => {
    const { onCancel, isLoading } = this.props
    if (isLoading) {
      return
    }

    onCancel()
  }

  handleClearAll = () => {
    const { isLoading } = this.props
    if (isLoading) {
      return
    }

    const { updateChosenProductsForComparison } = this.props
    updateChosenProductsForComparison([])
  }

  handleValidate = async () => {
    const { isLoading } = this.props
    if (isLoading) {
      return
    }

    const { comparisonBoard, onValidate, updateComparedProducts } = this.props
    const { chosenProductsForComparison, comparedProducts } = comparisonBoard

    this.setState({ processState: this.validationStatusMap.validating })

    await updateComparedProducts([...chosenProductsForComparison], [...comparedProducts])

    this.setState({ processState: this.validationStatusMap.completed })

    onValidate()
  }

  render() {
    const { processState } = this.state
    const { texts, device, comparisonBoard, searchkit, isLoading } = this.props
    const { chosenProductsForComparison } = comparisonBoard

    return (
      <div className="comparison_search_add_products_container">
        {searchkit !== null && (
          <SearchkitProvider searchkit={searchkit}>
            <div
              className={classNames({
                comparison_search_add_products_popup: true,
                isSafari: device.isSafari,
                isFirefox: device.isFirefox,
                desk: device.screenType === screenTypesMap.desk,
                ipadh: device.screenType === screenTypesMap.iPadH,
                ipadv: device.screenType === screenTypesMap.iPadV,
                iphonexr: device.screenType === screenTypesMap.iPhoneXR,
                iphonese: device.screenType === screenTypesMap.iPhoneSE,
              })}
            >
              <div className={classNames({ comparison_popup_head: true })}>
                <div className={classNames({ comparison_head_title: true })}>
                  <span className={classNames({ big: true })}>{texts.add_to_comparison}</span>
                  <span className={classNames({ small: true })}>{texts.select_up_to_5_models}</span>
                </div>
                <div
                  className={classNames({
                    comparison_head_cancel: true,
                    theme_color_hover: true,
                    disabled: isLoading,
                  })}
                  role="button"
                  tabIndex={0}
                  onClick={() => this.handleCancel()}
                  onKeyPress={() => this.handleCancel()}
                >
                  <span>{texts.cancel}</span>
                </div>
                <div
                  className={classNames({
                    comparison_head_search_box_container: true,
                  })}
                >
                  <SearchBox searchOnChange queryBuilder={customQueryBuilder} />
                </div>
                <div className={classNames({ comparison_head_sort: true })}>
                  <SearchSort />
                </div>
                <div className={classNames({ comparison_head_filter: true })}>
                  <SearchFilter />
                </div>
              </div>
              <div className={classNames({ comparison_popup_content: true })}>
                <Hits hitsPerPage={100} mod="sk-hits-grid" itemComponent={ComparisonSearchHit} />
                <Pagination showNumbers showLast pageScope={4} listComponent={PaginationComponent} />
                {/* drawHits() */}
              </div>
              <div
                className={classNames({
                  popup_footer: true,
                  isSafari: device.isSafari,
                  isFirefox: device.isFirefox,
                  desk: device.screenType === screenTypesMap.desk,
                  ipadh: device.screenType === screenTypesMap.iPadH,
                  ipadv: device.screenType === screenTypesMap.iPadV,
                  iphonexr: device.screenType === screenTypesMap.iPhoneXR,
                  iphonese: device.screenType === screenTypesMap.iPhoneSE,
                })}
              >
                {chosenProductsForComparison.length > 1 && (
                  <div
                    className={classNames({
                      sm_btn: true,
                      secondary_btn: true,
                      btn_200: true,
                      clear_all_btn: true,
                      isSafari: device.isSafari,
                      isFirefox: device.isFirefox,
                      desk: device.screenType === screenTypesMap.desk,
                      ipadh: device.screenType === screenTypesMap.iPadH,
                      ipadv: device.screenType === screenTypesMap.iPadV,
                      iphonexr: device.screenType === screenTypesMap.iPhoneXR,
                      iphonese: device.screenType === screenTypesMap.iPhoneSE,
                      theme_color: true,
                      disabled: isLoading,
                    })}
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => {
                      this.handleClearAll()
                    }}
                    onClick={() => this.handleClearAll()}
                  >
                    {texts.clear_all} ({chosenProductsForComparison.length})
                  </div>
                )}
                {chosenProductsForComparison.length} {texts.products_selected}
                <div
                  className={classNames({
                    theme_primary_btn: true,
                    sm_btn: true,
                    btn_200: true,
                    add_to_board_btn: true,
                    isSafari: device.isSafari,
                    isFirefox: device.isFirefox,
                    desk: device.screenType === screenTypesMap.desk,
                    ipadh: device.screenType === screenTypesMap.iPadH,
                    ipadv: device.screenType === screenTypesMap.iPadV,
                    iphonexr: device.screenType === screenTypesMap.iPhoneXR,
                    iphonese: device.screenType === screenTypesMap.iPhoneSE,
                    disabled: isLoading,
                  })}
                  role="button"
                  tabIndex={0}
                  onKeyDown={() => {
                    this.handleValidate()
                  }}
                  onClick={() => this.handleValidate()}
                >
                  <span
                    className={classNames({
                      btn_label_with_icon: processState !== this.validationStatusMap.choosingProducts,
                      btn_label_with_working_icon: processState === this.validationStatusMap.validating,
                      btn_label_with_check_icon: processState === this.validationStatusMap.completed,
                    })}
                  >
                    {texts.validate} ({chosenProductsForComparison.length})
                  </span>
                  {processState === this.validationStatusMap.validating && (
                    <div
                      className={classNames({
                        btn_spinner_container: true,
                      })}
                    >
                      <span className={classNames({ btn_spinner: true })} />
                    </div>
                  )}
                  {processState === this.validationStatusMap.completed && <CheckWhite />}
                </div>
              </div>
            </div>
          </SearchkitProvider>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    device: state.device,
    texts: state.texts.values,
    authentication: state.authentication,
    search: state.search,
    searchkit: state.search.searchkit,
    comparisonBoard: state.comparisonBoard,
    isLoading: state.comparisonBoard.nodePopup.isLoading,
  }
}

export default connect(mapStateToProps, actionCreators)(ComparisonSearchAddProducts)
